import {
  loadImage,
  mergeImages,
} from '../../../Templates/Designs/Utils/imageLoader';
import Konva from 'konva';
import html2canvas from 'html2canvas';
import MultipartUploader from '../../../Components/MultipartUploader';
const appConfig = require('../../../data.json');

export const createPreviewSlice = (set, get) => ({
  previewImage: null,
  previewConfig: null,
  isPreviewLoading: false,
  mergedImage: null,
  brightnessFilter: false,
  isUploading: false,
  screenShotUrl: null,
  whiteInkImageUrl: null,
  svgDimensions: {width: 0, height: 0},
  setSvgDimensions: (dimensions) => set({svgDimensions: dimensions}),
  setScreenShotUrl: (url) => set({screenShotUrl: url}),
  setWhiteInkImageUrl: (url) => set({whiteInkImageUrl: url}),
  setPreviewImage: (image) => set({previewImage: image}),
  setPreviewConfig: (config) => set({previewConfig: config}),
  setBrightnessFilter: (value) => set({brightnessFilter: value}),
  generatePreviewImage: async () => {
    if (get().isPreviewLoading) return;

    set({isPreviewLoading: true});
    try {
      const stageRef = get().stageRef;
      if (!stageRef?.current) return;

      const groupNode = stageRef.current.findOne('Group');
      const maskLayer = groupNode?.findOne('#cutting-mold-layer');

      if (!groupNode) return;

      const GROUP_SIZE = get().groupSize;

      // 暫時隱藏

      if (maskLayer) {
        maskLayer.opacity(0);
      }

      // 擷取 Group 內容
      const groupDataURL = groupNode.toDataURL({
        pixelRatio: 2,
        width: GROUP_SIZE,
        height: GROUP_SIZE,
        x: groupNode.x(),
        y: groupNode.y(),
        mimeType: 'image/png',
        imageSmoothingEnabled: true,
      });

      // 還原
      if (maskLayer) {
        maskLayer.opacity(1);
      }

      // 載入預覽底圖和 Group 內容
      const groupImg = await loadImage(groupDataURL);

      // 合併圖片
      const mergedImage = await mergeImages(
        get().previewImage,
        groupImg,
        get().previewConfig,
        get().groupSize,
      );

      set({mergedImage});
    } catch (error) {
      console.error('擷取預覽圖時發生錯誤:', error);
    } finally {
      set({isPreviewLoading: false});
    }
  },
  captureScreenshot: async (app) => {
    const token = get().token;
    set({isUploading: true});

    let uploader = null;

    try {
      // 建立一個 Promise 來處理上傳流程
      const screenFile = await new Promise((resolve, reject) => {
        html2canvas(document.body, {
          logging: false,
          useCORS: true,
          scale: 2,
        }).then((canvas) => {
          canvas.toBlob(async (blob) => {
            const file = new File([blob], 'screenshot.png', {
              type: 'image/png',
            });

            try {
              // 在需要時才建立 uploader 實例
              uploader = new MultipartUploader({
                host: appConfig.endpoint.revStorageHost,
                debug: appConfig.debug,
                onComplete: ({url}) => {
                  resolve(url);
                },
              });

              uploader.setToken(token);
              const result = await uploader.upload(file);

              if (!result.successful) {
                reject(new Error('上傳失敗'));
              }
            } catch (error) {
              reject(error);
            }
          });
        });
      });

      set({screenShotUrl: screenFile});
    } catch (error) {
      console.error('擷取螢幕截圖時發生錯誤:', error);
      throw error;
    } finally {
      // 確保清理 uploader
      if (uploader) {
        uploader.close();
      }
      set({isUploading: false});
    }
  },
  captureWhiteInkImage: async (app) => {
    set({isUploading: true});
    const GROUP_SIZE = get().groupSize;
    try {
      const stageRef = get().stageRef;
      if (!stageRef?.current) {
        throw new Error('Stage reference not found');
      }
      const groupNode = stageRef.current.findOne('Group');
      const maskLayer = groupNode.findOne('#cutting-mold-layer');

      // 儲存原始狀態
      const originalOpacity = maskLayer.opacity();
      const originalScale = {
        x: groupNode.scaleX(),
        y: groupNode.scaleY(),
      };

      // 設定透明度
      maskLayer.opacity(0);

      // 計算縮放比例
      const scaleX = get().svgDimensions.width / GROUP_SIZE;
      const scaleY = get().svgDimensions.height / GROUP_SIZE;
      groupNode.scale({
        x: originalScale.x * scaleX,
        y: originalScale.y * scaleY,
      });

      const whiteBackground = new Konva.Rect({
        x: 0,
        y: 0,
        width: get().svgDimensions.width,
        height: get().svgDimensions.height,
        fill: 'white',
      });

      groupNode.add(whiteBackground);
      whiteBackground.moveToBottom();

      const whiteInkImage = await new Promise((resolve, reject) => {
        groupNode.toBlob({
          callback: (blob) => {
            maskLayer.opacity(originalOpacity);
            groupNode.scale(originalScale);
            whiteBackground.destroy();
            resolve(new File([blob], 'whiteInk.png', {type: 'image/png'}));
          },
          pixelRatio: 1,
          width: get().svgDimensions.width,
          height: get().svgDimensions.height,
          x: groupNode.x(),
          y: groupNode.y(),
          mimeType: 'image/png',
        });
      });

      const whiteInkFile = await app.actions.uploadUserImage(
        whiteInkImage,
        get().token,
      );
      set({whiteInkImageUrl: whiteInkFile.url});
    } catch (error) {
      console.error('擷取白墨圖片時發生錯誤:', error);
      throw error;
    } finally {
      set({isUploading: false});
    }
  },
  captureAndUploadImages: async (app) => {
    set({isUploading: true});
    const GROUP_SIZE = get().groupSize;
    try {
      const stageRef = get().stageRef;
      if (!stageRef?.current) return;

      const groupNode = stageRef.current.findOne('Group');
      const maskLayer = groupNode.findOne('#cutting-mold-layer');

      // 儲存原始狀態
      const originalOpacity = maskLayer.opacity();
      const originalScale = {
        x: groupNode.scaleX(),
        y: groupNode.scaleY(),
      };

      const [stageFile, groupFile] = await Promise.all([
        // stageFile 維持原樣
        new Promise((resolve) => {
          stageRef.current.toBlob({
            callback: (blob) =>
              resolve(new File([blob], 'stage.png', {type: 'image/png'})),
            pixelRatio: 1,
            width: stageRef.current.width(),
            height: stageRef.current.height(),
            mimeType: 'image/png',
          });
        }),
        // 只對 groupFile 進行縮放處理
        new Promise((resolve) => {
          // 計算縮放比例
          const scaleX = get().svgDimensions.width / GROUP_SIZE;
          const scaleY = get().svgDimensions.height / GROUP_SIZE;
          groupNode.scale({
            x: originalScale.x * scaleX,
            y: originalScale.y * scaleY,
          });

          // 先隱藏 maskLayer
          maskLayer.opacity(0);

          groupNode.toBlob({
            callback: (blob) => {
              // 還原原始狀態
              groupNode.scale(originalScale);
              maskLayer.opacity(originalOpacity);
              resolve(new File([blob], 'group.png', {type: 'image/png'}));
            },
            pixelRatio: 1,
            width: get().svgDimensions.width,
            height: get().svgDimensions.height,
            x: groupNode.x(),
            y: groupNode.y(),
            mimeType: 'image/png',
          });
        }),
      ]);

      const [stageUrl, groupUrl] = await Promise.all([
        app.actions.uploadUserImage(stageFile, get().token),
        app.actions.uploadUserImage(groupFile, get().token),
      ]);

      return {
        stageUrl: stageUrl.url,
        groupUrl: groupUrl.url,
      };
    } catch (error) {
      console.error('上傳圖片時發生錯誤:', error);
      throw error;
    } finally {
      set({isUploading: false});
    }
  },
});
